// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-tsx": () => import("./../src/pages/auth.tsx" /* webpackChunkName: "component---src-pages-auth-tsx" */),
  "component---src-pages-changepassword-tsx": () => import("./../src/pages/changepassword.tsx" /* webpackChunkName: "component---src-pages-changepassword-tsx" */),
  "component---src-pages-create-tsx": () => import("./../src/pages/create.tsx" /* webpackChunkName: "component---src-pages-create-tsx" */),
  "component---src-pages-event-tsx": () => import("./../src/pages/event.tsx" /* webpackChunkName: "component---src-pages-event-tsx" */),
  "component---src-pages-events-tsx": () => import("./../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-tsx": () => import("./../src/pages/landing.tsx" /* webpackChunkName: "component---src-pages-landing-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */)
}

