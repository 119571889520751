// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:02c7766c-a3b1-4dd1-a3ac-0dceeb5e38c9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_sX0ZKUu3n",
    "aws_user_pools_web_client_id": "69milul5msh3t4ctv0s8v3pkt2",
    "oauth": {
        "domain": "chefy963076fc-963076fc-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.chefslabs.com/",
        "redirectSignOut": "https://www.chefslabs.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;